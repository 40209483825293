import React from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  color?:
    | 'bg-brand-gold'
    | 'bg-text-muted'
    | 'bg-text-muted-2x'
    | 'bg-white'
    | 'bg-black'
  disabled?: boolean
  disabledColor?: 'bg-gray-bg-2'
  rotate?: number
  size?: number
}

const Triangle: React.FC<Props> = ({
  className,
  children,
  color,
  disabled,
  disabledColor,
  rotate,
  size,
  style,
  ...other
}) => {
  const bgEnabled = color ? color : 'bg-brand-gold'
  const bgDisabled = disabledColor ? disabledColor : 'bg-gray-bg-2'
  const bgColor = disabled ? bgDisabled : bgEnabled

  let widthVal
  let heightVal
  if (size) {
    widthVal = `${size}px`
    heightVal = `${size - size / 2}px`
  } else {
    widthVal = '100%'
    heightVal = '50%'
  }

  return (
    <div
      className={
        `transition-all duration-300 ease-in-out ${bgColor}` +
        (className ? ` ${className}` : '')
      }
      style={{
        width: widthVal,
        height: heightVal,
        clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
        transform: `rotate(${rotate == undefined ? 180 : rotate}deg)`,
        ...style,
      }}
      {...other}
    />
  )
}

export default Triangle
